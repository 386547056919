var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.is_template)?_c('div',[_c('placeholder-block',{attrs:{"label":"Estimate Groups","block":_vm.block,"can_delete":false}})],1):_c('div',[_c('el-card',{staticClass:"content-block estimate-group",attrs:{"shadow":"never"}},[_c('div',{staticClass:"heading",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"grip"},[_vm._v("⣿")]),_c('span',{staticClass:"label"},[_vm._v("Estimate group")]),_c('span',{staticClass:"title",class:{excluded: _vm.groupExcluded}},[_vm._v(" "+_vm._s(_vm.group?.title)+" ")]),(_vm.group)?_c('div',{staticClass:"controls"},[_c('span',[_vm._v("Options:")]),_c('el-select',{staticClass:"select-detail",attrs:{"disabled":_vm.read_only,"multiple":""},on:{"change":(val) =>
                            _vm.$emit('update', {
                                ..._vm.block,
                                values: val,
                            })},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('el-option-group',{key:"toggles",attrs:{"label":"Toggles"}},[(
                                _vm.hasExcludedTasks ||
                                _vm.hasExcludedSubtasks ||
                                _vm.groupExcluded
                            )?_c('el-option',{attrs:{"label":"Show excluded","value":_vm.DOCUMENT_GROUP_TOGGLES.EXCLUDED}}):_vm._e(),_c('el-option',{attrs:{"disabled":!_vm.showSubtasks || (!_vm.showDays && !_vm.showHours),"label":"Show subtask time","value":_vm.DOCUMENT_GROUP_TOGGLES.SUBTASK_TIME}})],1),_c('el-option-group',{key:"display",attrs:{"label":"Display"}},[_c('el-option',{attrs:{"label":"Subtasks","value":_vm.DOCUMENT_GROUP_DETAIL.SUBTASKS}}),_c('el-option',{attrs:{"label":"Days","value":_vm.DOCUMENT_GROUP_DETAIL.DAYS}}),_c('el-option',{attrs:{"label":"Hours","value":_vm.DOCUMENT_GROUP_DETAIL.HOURS}})],1)],1)],1):_vm._e()]),(!_vm.group)?_c('el-skeleton',{attrs:{"rows":3,"animated":""}}):(_vm.groupExcluded && !_vm.showExcluded)?_c('el-alert',{staticClass:"excluded-notice",attrs:{"type":"warning","closable":false}},[_vm._v(" This group is excluded, and will only appear in the final document if 'show excluded' is enabled. ")]):_c('table',{staticClass:"group-table"},[_c('tr',[_c('th',{staticClass:"col-task"},[_vm._v("Task")]),(_vm.showDays)?_c('th',{staticClass:"col-value"},[_vm._v("Days")]):_vm._e(),(_vm.showHours)?_c('th',{staticClass:"col-value"},[_vm._v("Hours")]):_vm._e()]),_vm._l((_vm.filteredTasks),function(task){return [_c('tr',{key:task.id,class:{excluded: _vm.getTaskExcluded(task)}},[_c('td',{staticClass:"col-task",class:{
                            sub: _vm.showSubtasks,
                            header: _vm.showSubtaskTime,
                        }},[_vm._v(" "+_vm._s(task.description)+" ")]),(_vm.showDays)?_c('td',{staticClass:"col-value",class:{header: _vm.showSubtaskTime},attrs:{"rowSpan":!_vm.showSubtasks || _vm.showSubtaskTime
                                ? 1
                                : 1 + _vm.getFilteredSubtasks(task).length}},[_vm._v(" "+_vm._s(_vm.task_days(task))+" ")]):_vm._e(),(_vm.showHours)?_c('td',{staticClass:"col-value",class:{header: _vm.showSubtaskTime},attrs:{"rowSpan":!_vm.showSubtasks || _vm.showSubtaskTime
                                ? 1
                                : 1 + _vm.getFilteredSubtasks(task).length}},[_vm._v(" "+_vm._s(_vm.task_hours(task))+" ")]):_vm._e()]),(_vm.showSubtasks)?[_vm._l((_vm.getFilteredSubtasks(task)),function(subtask){return _c('tr',{key:`${task.id}_${subtask.id}`,class:{
                            excluded: task.excluded || subtask.excluded,
                        }},[_c('td',{staticClass:"col-subtask"},[_vm._v(" "+_vm._s(subtask.description)+" ")]),(_vm.showDays && _vm.showSubtaskTime)?_c('td',{staticClass:"col-value"},[_vm._v(" "+_vm._s(_vm.getSubtaskDays(subtask, task))+" ")]):_vm._e(),(_vm.showHours && _vm.showSubtaskTime)?_c('td',{staticClass:"col-value"},[_vm._v(" "+_vm._s(_vm.getSubtaskHours(subtask, task))+" ")]):_vm._e()])}),(_vm.showSubtasks)?_c('tr',{key:`${task.id}_spacer`},[_c('td',{staticClass:"spacer",attrs:{"colspan":_vm.columns}})]):_vm._e()]:_vm._e()]}),(_vm.showDays || _vm.showHours)?_c('tr',[_c('td',{staticClass:"total-time-label"},[_vm._v("Total time")]),(_vm.showDays)?_c('td',{staticClass:"total-time",class:{excluded: _vm.groupExcluded}},[_vm._v(" "+_vm._s(_vm.totalDays.toFixed(2))+" ")]):_vm._e(),(_vm.showHours)?_c('td',{staticClass:"total-time",class:{excluded: _vm.groupExcluded}},[_vm._v(" "+_vm._s(_vm.totalHours.toFixed(2))+" ")]):_vm._e()]):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }