var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"content-block text",attrs:{"shadow":"never"}},[(_vm.editingBlock)?[_c('div',{staticClass:"heading",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"grip"},[_vm._v("⣿")]),_c('el-input',{ref:"heading",class:{
                    invalid: _vm.blockInvalid,
                    empty: !_vm.blockInvalid && !_vm.locked && _vm.headingEmpty,
                },attrs:{"readonly":_vm.read_only || (!_vm.is_template && _vm.locked),"placeholder":_vm.headingPlaceholderText},on:{"input":_vm.updateBlock},model:{value:(_vm.editingBlock.heading),callback:function ($$v) {_vm.$set(_vm.editingBlock, "heading", $$v)},expression:"editingBlock.heading"}}),(!_vm.read_only)?[(_vm.is_template || _vm.editingBlock.mandatory)?_c('el-tooltip',{attrs:{"content":_vm.lockTooltip}},[_c('el-button',{staticClass:"control",attrs:{"icon":_vm.locked ? 'el-icon-lock' : 'el-icon-unlock',"plain":"","type":_vm.locked ? null : 'warning'},on:{"click":_vm.toggleLocked}})],1):_vm._e(),_c('el-button',{staticClass:"control",attrs:{"plain":"","type":"danger","icon":"el-icon-delete","disabled":!_vm.is_template && _vm.locked},on:{"click":_vm.deleteBlock}})]:_vm._e()],2),_c('text-editor',{ref:"content_editor",class:{
                invalid: _vm.blockInvalid,
                empty: !_vm.blockInvalid && _vm.contentEmpty,
            },attrs:{"editable":!_vm.read_only,"content":_vm.editingBlock.content,"placeholder":"Block content","floating_menu":false,"drag_drop":false},on:{"update:content":[function($event){return _vm.$set(_vm.editingBlock, "content", $event)},_vm.updateBlock]}})]:[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('el-skeleton',{attrs:{"rows":1,"animated":""}})],1),_c('el-skeleton',{attrs:{"rows":3,"animated":""}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }